import {
  PuxButton,
  PuxModal,
  PuxSheet,
  PuxText,
  PuxTypography,
  useBreakpoint,
} from "@phonero/pux-react"
import css from "./PhoneroCloudDeactivate.module.scss"
import { ApiProductState } from "@phonero/common-graphql/types"
import { Product } from "../ProductList/ProductItem"
import cx from "classnames"

// This component handles deactivation dialog for PhoneroCloud service.
export const PhoneroCloudDeactivate = ({
  product,
  isOpen,
  onDeactivate,
  onDismiss,
}: {
  product: Product
  isOpen: boolean
  onDeactivate: (product: Product) => void
  onDismiss: () => void
}) => {
  const isDesktop = useBreakpoint("md", { useMin: true })

  if (product.state !== ApiProductState.Active) return null

  return (
    <>
      <PuxModal
        isOpen={isOpen}
        cssClass={isDesktop ? css.modalContainerMedium : ""}
        aria-label="Edit Services Wizard"
        onWillDismiss={() => onDismiss()}
      >
        <PuxSheet>
          <PuxTypography variant="h3" style={{ textAlign: "center" }}>
            {"Vil du virkelig avslutte Phonero Sky?"}
          </PuxTypography>

          <PuxTypography variant="body1" style={{ textAlign: "center" }}>
            {
              "Dersom du velger å avslutte Phonero Sky så vil du i løpet av de neste dagene miste tilgangen til innholdet du har lagret i skyen."
            }
          </PuxTypography>

          <div className={cx(css.deactivateButtonsContainer)}>
            <PuxButton
              style={{ marginRight: 8, minWidth: "100px" }}
              onClick={() => onDismiss()}
              color="medium"
              fill="solid"
              size="default"
            >
              <PuxText>{"Avbryt"}</PuxText>
            </PuxButton>

            <PuxButton
              style={{ marginLeft: 8, minWidth: "100px" }}
              onClick={() => onDeactivate(product)}
              fill="solid"
              color="primary"
              size="default"
            >
              <PuxText>{"Avslutt"}</PuxText>
            </PuxButton>
          </div>
        </PuxSheet>
      </PuxModal>
    </>
  )
}
